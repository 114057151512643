import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useTablet } from "@modules/hooks/useMedia";
import TTBBFooterLogo from "@public/images/ttbb/img_ttbb_footer_logo.svg";
import { useDomain } from "@modules/hooks/useDomain";
import { IsTripbtozApp, IsTTBBApp } from "@modules/utils/TripbtozApp";
import InstaIcon from "/public/images/common/img_footer_insta.svg";
import FacebookIcon from "/public/images/common/img_footer_facebook.svg";
import YoutubeIcon from "/public/images/common/img_footer_youtube.svg";
import NaverblogIcon from "/public/images/common/img_footer_blog.svg";
import AppStoreIcon from "/public/images/common/img_footer_appstore.svg";
import PlayStoreIcon from "/public/images/common/img_footer_googleplay.svg";
export default function Footer({
  className
}) {
  const {
    t
  } = useTranslation("common");
  const isTablet = useTablet();
  const isTTBB = useDomain();
  const callNumber = isTTBB ? `${t("footer.customer.center.ttbb.telnumber")}` : `${t("footer.customer.center.telnumber")}`;
  const corpUrl = IsTTBBApp() ? `ttbb://external?url=${encodeURIComponent(process.env.NEXT_PUBLIC_CORP_URL)}` : process.env.NEXT_PUBLIC_CORP_URL;
  const target = IsTTBBApp() ? "_self" : "_blank";
  return <footer className={`relative block border-t-[1px] border-solid border-t-Bg-TintWhite px-[24px] transition-all delay-[0.3s] mobile:p-[0_16px] ${className} bg-white text-Gray-500`}>
      <div className={`footer m-[0_auto_0_auto] flex max-w-[1180px] flex-wrap content-center items-center py-[40px]
                    ${isTTBB && "flex items-center justify-between"}`}>
        <div className={`footer_text tablet:h-[205px] tablet:w-full tablet:p-[0_24px] mobile:h-auto mobile:w-full mobile:px-0 mobile:pt-[19px] ${isTTBB ? "w-fit" : "w-full"}`}>
          {/* 공통 Start */}
          <ul className="C_12_100_Regular mobile:SC_11_100_Regular w-full pb-[8px] text-Gray-500">
            <li className={"inline-block pr-[12px]"}>
              <a href={corpUrl} target={target} className="block py-[10px]">
                {t("footer.about.us.txt")}
              </a>
            </li>
            <li className={"inline-block pr-[12px]"}>
              <Link href={{
              pathname: "/terms/service",
              query: {
                key: "main"
              }
            }}>
                {t("footer.btn.terms.person")}
              </Link>
            </li>
            <li className={"SC_11_100_Bold inline-block pr-[12px]"}>
              <Link href={{
              pathname: "/terms/privacy",
              query: {
                key: "main"
              }
            }}>
                {t("footer.btn.terms.policy")}
              </Link>
            </li>
            {isTTBB && <li className={"inline-block pr-[12px]"}>
                <Link href={{
              pathname: "/cmcenter/notice",
              query: {
                key: "main"
              }
            }}>
                  {t("setting.cmcenter.notice")}
                </Link>
              </li>}
          </ul>
          {/* 공통 End */}

          {/* 웹 Start */}
          <div className="C_12_145_Regular w-full space-y-2 mobile:hidden">
            <p>
              {t("footer.customer.center")} {callNumber} {t("footer.business.hours,weekday")}
              <BulletSpan>{t("footer.business.hours.weekend")}</BulletSpan>
            </p>
            <p>
              {t("footer.business.name")}
              <BulletSpan>{t("footer.business.ceo")}</BulletSpan>
              <BulletSpan>{t("footer.business.manager")}</BulletSpan>
              <BulletSpan>{t("footer.business.addr")}</BulletSpan>
            </p>
            <p>
              {t("footer.business.number")}{" "}
              <a className="C_12_145_Bold" href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7788600179&apv_perm_no=" target="_blank" rel="noreferrer">
                {t("footer.business.info")}
              </a>
              <BulletSpan>{t("footer.business.tour.number")}</BulletSpan>
              <BulletSpan>{t("footer.business.sale.text")}</BulletSpan>
            </p>
          </div>
          {/* 웹 End */}

          {/* 모바일 Start */}
          <p className="SC_11_145_Regular hidden w-full pb-[8px] pt-[0] mobile:block">
            {t("footer.customer.center")} {callNumber}
            <br />
            {t("footer.business.hours,weekday")}
            <BulletSpan>{t("footer.business.hours.weekend")}</BulletSpan>
          </p>
          <p className="SC_11_145_Regular hidden w-full pb-[2px] pt-0 mobile:block">
            {t("footer.business.name")}
            <em className="block pt-[2px]">
              {t("footer.business.ceo")}
              <BulletSpan>{t("footer.business.manager")}</BulletSpan>
            </em>
            <em className="block pt-[2px]">{t("footer.business.addr")}</em>
          </p>
          <p className="SC_11_145_Regular hidden w-full pt-0 mobile:block">
            {t("footer.business.number")}
            <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7788600179&apv_perm_no=" target="_blank" rel="noreferrer" className="SC_11_145_Bold ml-[8px] cursor-pointer">
              {t("footer.business.info")}
            </a>
            <em className="block pt-[2px]">{t("footer.business.tour.number")}</em>
            <em className="block pt-[2px]">{t("footer.business.sale.text")}</em>
          </p>
          {/* 모바일 End */}

          {/* 공통 Start */}
          <div className="SC_11_145_Regular mt-[9px] w-full mobile:mt-[8px]">
            {isTTBB && <p className="">{t("footer.business.support")}</p>}
            <p className="mt-[8px] text-Gray-300">{t("footer.copyright")}</p>
            <p className="mt-5 text-Gray-500">{t("footer.telecommunications.notice")}</p>
          </div>

          {/*연관 채널 영역*/}
          {!isTTBB && <div className="mt-[28px] flex justify-between mobile:mb-0 mobile:flex-col">
              {/*좌측 소셜 계정*/}
              <div className="flex flex-row gap-2">
                <a href="https://www.instagram.com/tripbtoz/" target="_blank" rel="noreferrer noopener">
                  <InstaIcon w={40} h={40} />
                </a>
                <a href="https://www.facebook.com/Tripbtoz" target="_blank" rel="noreferrer noopener">
                  <FacebookIcon w={40} h={40} />
                </a>
                <a href="https://www.youtube.com/c/%ED%8A%B8%EB%A6%BD%EB%B9%84%ED%86%A0%EC%A6%88" target="_blank" rel="noreferrer noopener">
                  <YoutubeIcon w={40} h={40} />
                </a>
                <a href="https://blog.naver.com/tripbtoz" target="_blank" rel="noreferrer noopener">
                  <NaverblogIcon w={40} h={40} />
                </a>
              </div>
              {/*우측 앱 & 플레이 스토어*/}
              {!IsTripbtozApp() && <>
                  <div className="flex flex-row gap-1 mobile:mt-5">
                    <a href="https://apps.apple.com/kr/app/%ED%8A%B8%EB%A6%BD%EB%B9%84%ED%86%A0%EC%A6%88-play-share-stay/id1206972376" target="_blank" rel="noreferrer noopener">
                      <AppStoreIcon w={132} h={40} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.tripbtoz.androidgotivia&pli=1" target="_blank" rel="noreferrer noopener">
                      <PlayStoreIcon w={132} h={40} />
                    </a>
                  </div>
                </>}
            </div>}
          {/* 공통 End */}
        </div>
        {!isTablet && isTTBB && <TTBBFooterLogo />}
      </div>
    </footer>;
}
const BulletSpan = ({
  children
}) => {
  return <span className={"relative ml-[4px] inline-block rounded-[100px] pl-[6px] before:absolute before:left-0 before:top-[7px] before:h-[2px] before:w-[2px] before:bg-Gray-300 before:content-['']"}>
      {children}
    </span>;
};