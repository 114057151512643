import { useDomain } from "@modules/hooks/useDomain";


export function useImgPath() {
    const isTTBB = useDomain();
    const fileN = () => {
        if(isTTBB) {
            return 'ttbb'
        } else {
            return 'common'
        }
    }
    const type = fileN();
    return type;
}

