import { atom } from "jotai";
/** 로딩상태값을 갖는 atom */
export const LoadingAtom = atom(false);
/** SearchBox의 DatePickerBox를 Open하기위한 atom */
export const dateBoxOpenAtom = atom(false);
export const firstEntryCheckAtom = atom(false);
/** hotel상세 페이지 video 오픈 여부 atom */
export const videoOpenAtom = atom(false);
/** hotel상세 페이지 인플루언서 여부 atom */
export const isSrookpayAtom = atom(null);
export const checkOutSupplyType = atom(null);
/** 호텔 리스트뷰, 썸네일뷰 타입 여부 atom */
export const hotelListViewTypeAtom = atom(true);
/** genie를 통한 진입시 초기 팝업 오픈할지 여부  */
export const getGenieEnterPopupAtom = atom(true);
/** 사용자가 몇번째 객실 타입의 몇번째 요금제를 클릭했는지 기록해두는 atom */
export const realIndexClickRoom = atom(-1);
/** 메인 데이터 manifest 저장 atom */
export const webMainAtom = atom({});

/** 메인페이지 개선 분기 atom */
export const mainPageVer2Atom = atom(false);
/** 메인페이지 개선 - 빅배너 visible 여부 atom */
export const visibleBigBannerAtom = atom(true);
/** 메인페이지 개선 - 상단 네비바 서치박스 클릭 여부 atom */
export const navbarSearchboxAtom = atom(false);
