import Image from "next/image";
import Link from "next/link";
import { useDomain } from "@modules/hooks/useDomain";
import { useTablet } from "@modules/hooks/useMedia";
import { useImgPath } from "@modules/hooks/useImgPath";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom } from "@store/global";
export default function Logo({
  className
}) {
  const [mainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기
  const isTTBB = useDomain();
  const isTablet = useTablet();
  const type = useImgPath();
  const LOGO_URL = `/images/${type}/img_logo_main.svg`;
  return isTTBB || isTablet || !mainPageVer2 ? <Link href="/" className={`h-full ${getValueByDomain("w-[174px] tablet:h-[32px] tablet:w-[116px] mobile:h-[24px] mobile:w-[87px]", "h-[56px] w-[56px] tablet:h-[28.8px] tablet:w-[28.8px]")} relative ${className}`}>
      <Image src={LOGO_URL} alt="logo" sizes="(max-width: 768px) [174px]" fill priority />
    </Link> : <Link href="/" className={`px-4 ${className}`}>
      <Image src="/images/common/img_tripbtoz.png" alt="logo" className="h-[30px] w-[110px]" loading={"eager"} width={110 * 5} height={30 * 5} quality={100} />
    </Link>;
}